<template>
	<div>
		<div class="border p-5 mb-5">
			<h6>Kampüs</h6>
			<campus-selectbox v-model="campus"/>
			<span>{{ campus }}</span>
		</div>
		<div class="border p-5">
			<b-row>
				<b-col md="12" class="mb-4">
					<h6>Null</h6>
					<building-selectbox :campus_id="campus" v-model="value1"/>
					<span>{{ value1 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Single Selected</h6>
					<building-selectbox :campus_id="campus" v-model="value2"/>
					<span>{{ value2 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Multiple Selected</h6>
					<building-selectbox :campus_id="campus" :multiple="true" v-model="value3"/>
					<span>{{ value3 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Validate Error</h6>
					<building-selectbox :campus_id="campus" v-model="value4" :validateError="value4ValidateError"/>
					<span>{{ value4 }}</span>
				</b-col>
				<b-col cols="12">
					<b-button variant="primary" @click="change()">
						{{ $t('change').toUpper() }}
					</b-button>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
	import BuildingSelectbox from "@/components/interactive-fields/BuildingSelectbox";
	import CampusSelectbox from "@/components/interactive-fields/CampusSelectbox";

	export default {
		components: {
			BuildingSelectbox,
			CampusSelectbox,
		},
		data() {
			return {
				campus: 1,
				value1: null,
				value2: 5,
				value3: [5, 6],
				value4: 5,
				value4ValidateError: 'Validate Error is Working',
			}
		},
		methods: {
			change() {
				this.campus = 1
				this.value2 = 6
				this.value3 = [5, 6, 7]
				this.value4 = 6
				this.value4ValidateError = 'Error'
			}
		}
	};
</script>

