<template>
	<div>
		<b-row>
			<b-col md="12" class="mb-4">
				<div class="border p-5">
					<h6>Null</h6>
					<country-and-university-selectbox v-model="value12" :country="value11"/>
					<span>{{ value12 }}</span>
				</div>
			</b-col>
			<b-col md="12" class="mb-4">
				<div class="border p-5">
					<h6>Single Selected</h6>
					<country-and-university-selectbox v-model="value22" :country="value21"/>
					<span>{{ value22 }}</span>
				</div>
			</b-col>
			<b-col md="12" class="mb-4">
				<div class="border p-5">
					<h6>Multiple Selected</h6>
					<country-and-university-selectbox v-model="value32" :multiple="true" :country="value31"/>
					<span>{{ value32 }}</span>
				</div>
			</b-col>
			<b-col md="12" class="mb-4">
				<div class="border p-5">
					<h6>Validate Error</h6>
					<country-and-university-selectbox v-model="value42" :country="value41" :validateError="value4ValidateError"/>
					<span>{{ value42 }}</span>
				</div>
			</b-col>
			<b-col cols="12">
				<b-button variant="primary" @click="change()">
					{{ $t('change').toUpper() }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	import CountryAndUniversitySelectbox from "@/components/interactive-fields/CountryAndUniversitySelectbox";

	export default {
		components: {
			CountryAndUniversitySelectbox,
		},
		data() {
			return {
				value11: null,
				value12: null,

				value21: 171,
				value22: 129,

				value31: 171,
				value32: [64,126],

				value41: 171,
				value42: 129,
				value4ValidateError: 'Validate Error is Working',
			}
		},
		methods: {
			change() {
				this.value21 = 43
				this.value22 = 225

				this.value31 = 43
				this.value32 = [225]

				this.value41 = 43
				this.value42 = 225
				this.value4ValidateError = 'Error'
			}
		}
	};
</script>

