<template>
	<div>
		<multiselect
			v-model="selected"
			label="text"
			track-by="value"
			:options="options"
			:class="validateError != '' ? 'box-border-color':''"
			:clear-on-select="!multiple"
			:close-on-select="!multiple"
			:placeholder="$t('select')"
			:multiple="multiple"
			:searchable="true"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			@input="handleInput($event, multiple)"
		>
			<template slot="selection" slot-scope="{ values, search, isOpen}" v-if="multiple">
                <span v-if="values.length && !isOpen" class="multiselect__single">
	                {{ translateNSelected(values) }}
                </span>
			</template>
			<span slot="noOptions">
                {{ $t('no_options') }}
            </span>
			<span slot="noResult">
                {{ $t('no_result') }}
            </span>
		</multiselect>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
	</div>
</template>

<script>
	// Helpers
	import translateNSelected from "@/helpers/translateNSelected";
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";
	
	// Services
	import UnitService from "@/services/UnitService";
	
	export default {
		props: {
			value: {
				type: Number | Array,
				default: null
			},
			validateError: {
				type: String,
				default: ''
			},
			multiple: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				selected: null,
				options: [],
			}
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options, this.multiple)
			}
		},
		created() {
			this.getOptions();
		},
		methods: {
			translateNSelected: translateNSelected,
			handleInput: handleInput,
			setSelected: setSelected,
			getOptions() {
				this.options = [];
				UnitService.getAllOptions()
				           .then(response => {
					           const data = response.data.data;
					           data.forEach((item) => {
						           this.options.push({
							           value: item.id,
							           text: this.getLocaleText(item, 'name'),
						           });
					           });
				           })
				           .then(() => {
					           this.selected = this.setSelected(this.value, this.options, this.multiple)
				           })
			}
		}
	}
</script>
