<template>
	<div>
		<div class="border p-5 mb-5">
			<h6>Bank</h6>
			<bank-selectbox v-model="bank"/>
			<span>{{ bank }}</span>
		</div>
		<div class="border p-5">
			<b-row>
				<b-col md="12" class="mb-4">
					<h6>Null</h6>
					<payment-types-selectbox v-model="value1" :bank_id="bank"/>
					<span>{{ value1 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Single Selected</h6>
					<payment-types-selectbox v-model="value2" :bank_id="bank"/>
					<span>{{ value2 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Validate Error</h6>
					<payment-types-selectbox v-model="value3" :bank_id="bank" :validate-error="value3ValidateError"/>
					<span>{{ value3 }}</span>
				</b-col>
				<b-col cols="12">
					<b-button variant="primary" @click="change()">
						{{ $t('change').toUpper() }}
					</b-button>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
	import BankSelectbox from "@/components/interactive-fields/BankSelectbox";
	import PaymentTypesSelectbox from "@/components/interactive-fields/PaymentTypesSelectbox";

	export default {
		components: {
			BankSelectbox,
			PaymentTypesSelectbox,
		},
		data() {
			return {
				bank: 3,

				value1: null,
				value2: 31,
				value3: 31,
				value3ValidateError: 'Validate Error is Working',
			}
		},
		methods: {
			change() {
				this.bank = 4

				this.value2 = 13
				this.value3 = 13
				this.value3ValidateError = 'Error'
			}
		}
	};
</script>

