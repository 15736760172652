<template>
    <div>
        <multiselect
            :placeholder="$t('select')"
            v-model="selected"
            :options="options"
            :class="validateError != '' ? 'box-border-color' : ''"
            @input="handleInput($event, multiple)"
            label="text"
            track-by="value"
            :multiple="multiple"
            :select-label="''"
            :selected-label="''"
            :deselect-label="''"
            :close-on-select="false"
            :searchable="true"
        >
            <template slot="selection" slot-scope="{ values, search, isOpen }" v-if="multiple">
                <span class="multiselect__single" v-if="values.length && !isOpen">
	                {{ translateNSelected(values) }}
                </span>
            </template>
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
            <span slot="noResult">
                {{ $t('no_result') }}
            </span>
        </multiselect>
        <span class="invalid-feedback-custom"
              v-show="validateError"
              v-html="validateError"
        />
    </div>
</template>

<script>
    // Helpers
    import translateNSelected from '@/helpers/translateNSelected';
    import handleInput from '@/helpers/handleInput';
    import setSelected from '@/helpers/setSelected';

    // Services
    import FacultyService from '@/services/FacultyService';

    // Other
    import qs from 'qs';

    export default {
        props: {
            code: {
                type: String
            },
            value: {
                default: null
            },
            multiple: {
                default: true
            },
            validateError: {
                type: String,
                default: ''
            },
            sort: {
                type: String,
                default: 'code'
            },
            public: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            value: function(newValue) {
                this.selected = this.setSelected(newValue, this.options, this.multiple)
            }
        },
        data() {
            return {
                selected: null,
                options: []
            }
        },
        created() {
            this.getOptions();
        },
        methods: {
            translateNSelected: translateNSelected,
            handleInput: handleInput,
            setSelected: setSelected,
            getOptions() {
                const config = {
                    params: {
                        sort: this.sort,
                        limit: -1
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };
                if (this.public) {
                    FacultyService.getAllPublic(config)
                                  .then((response) => {
                                      const data = response.data.data;
                                      data.map((item) => {
                                          this.options.push({
                                              value: item.code,
                                              text: item.code + ' - ' + this.getLocaleText(item, 'name')
                                          })
                                      })
                                  })
                                  .then(() => {
                                      this.selected = this.setSelected(this.value, this.options, this.multiple)
                                  })
                }
                else {
                    FacultyService.getAll(config)
                                  .then((response) => {
                                      const data = response.data.data;
                                      data.map((item) => {
                                          this.options.push({
                                              value: item.code,
                                              text: item.code + ' - ' + this.getLocaleText(item, 'name')
                                          })
                                      })
                                  })
                                  .then(() => {
                                      this.selected = this.setSelected(this.value, this.options, this.multiple)
                                  })
                }
            }
        }
    }
</script>
