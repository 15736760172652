<template>
	<div>
		<div class="border p-5 mb-5">
			<b-row>
				<b-col cols="12" class="mb-4">
					<h6>Fakülte</h6>
					<faculty-selectbox v-model="faculty_code"/>
					<span>{{ faculty_code }}</span>
				</b-col>
				<b-col cols="12" class="mb-4">
					<h6>Bölüm</h6>
					<department-selectbox v-model="department_code" :faculty_code="faculty_code"/>
					<span>{{ department_code }}</span>
				</b-col>
				<b-col cols="12" class="mb-4">
					<h6>Program</h6>
					<program-selectbox v-model="program_code" :faculty_code="faculty_code" :department_code="department_code"/>
					<span>{{ program_code }}</span>
				</b-col>
			</b-row>
		</div>
		<div class="border p-5">
			<b-row>
				<b-col md="12" class="mb-4">
					<h6>Null</h6>
					<curriculum-selectbox v-model="value1" :program_code="program_code"/>
					<span>{{ value1 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Single Selected</h6>
					<curriculum-selectbox v-model="value2" :program_code="program_code"/>
					<span>{{ value2 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Validate Error</h6>
					<curriculum-selectbox v-model="value3" :program_code="program_code" :validateError="value3ValidateError"/>
					<span>{{ value3 }}</span>
				</b-col>
				<b-col cols="12">
					<b-button variant="primary" @click="change()">
						{{ $t('change').toUpper() }}
					</b-button>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
	import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
	import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
	import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
	import CurriculumSelectbox from "@/components/interactive-fields/CurriculumSelectbox";

	export default {
		components: {
			FacultySelectbox,
			DepartmentSelectbox,
			ProgramSelectbox,
			CurriculumSelectbox,
		},
		data() {
			return {
				faculty_code: '04',
				department_code: '0401',
				program_code: '04018102',

				value1: null,
				value2: 1194,
				value3: 1194,
				value3ValidateError: 'Validate Error is Working',
			}
		},
		methods: {
			change() {
				this.faculty_code = '05'
				this.department_code = '0501'
				this.program_code = '05012101'

				this.value2 = 923
				this.value3 = 923
				this.value3ValidateError = 'Error'
			}
		}
	};
</script>

