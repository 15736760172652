<template>
	<div>
		<div class="border p-5 mb-5">
			<b-row>
				<b-col cols="12" class="mb-4">
					<h6>Şehir</h6>
					<city-selectbox v-model="city_id"/>
					<span>{{ city_id }}</span>
				</b-col>
			</b-row>
		</div>
		<div class="border p-5">
			<b-row>
				<b-col md="12" class="mb-4">
					<h6>Null</h6>
					<district-outline-selectbox v-model="value1" :city_id="city_id"/>
					<span>{{ value1 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Single Selected</h6>
					<district-outline-selectbox v-model="value2" :city_id="city_id"/>
					<span>{{ value2 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Validate Error</h6>
					<district-outline-selectbox v-model="value3" :city_id="city_id" :validateError="value3ValidateError"/>
					<span>{{ value3 }}</span>
				</b-col>
				<b-col cols="12">
					<b-button variant="primary" @click="change()">
						{{ $t('change').toUpper() }}
					</b-button>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
	import CitySelectbox from "@/components/interactive-fields/CitySelectbox";
	import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox";

	export default {
		components: {
			CitySelectbox,
			DistrictOutlineSelectbox,
		},
		data() {
			return {
				city_id: 34,
				value1: null,
				value2: 431,
				value3: 431,
				value3ValidateError: 'Validate Error is Working',
			}
		},
		methods: {
			change() {
				this.city_id = 6

				this.value2 = 73
				this.value3 = 73
				this.value3ValidateError = 'Error'
			}
		}
	};
</script>

