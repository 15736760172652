<template>
	<div>
		<b-row>
			<b-col md="12" class="mb-4">
				<h6>Null</h6>
				<radio-group-selectbox v-model="value1" :values="values"/>
				<span>{{ value1 }}</span>
			</b-col>
			<b-col md="12" class="mb-4">
				<h6>Single Selected</h6>
				<radio-group-selectbox v-model="value2" :values="values"/>
				<span>{{ value2 }}</span>
			</b-col>
			<b-col cols="12">
				<b-button variant="primary" @click="change()">
					{{ $t('change').toUpper() }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	import RadioGroupSelectbox from "@/components/interactive-fields/RadioGroupSelectbox";

	export default {
		components: {
			RadioGroupSelectbox,
		},
		data() {
			return {
				values: [
					{key: 1, text: 1},
					{key: 2, text: 2},
					{key: 3, text: 3},
				],
				value1: null,
				value2: 1,
			}
		},
		methods: {
			change() {
				this.value1 = 2
				this.value2 = 2
			}
		}
	};
</script>

