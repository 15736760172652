<template>
	<div>
		<multiselect
			v-model="selected"
			:options="options"
			label="text"
			track-by="value"
			:placeholder="$t('select')"
			:multiple="multiple"
			:close-on-select="!multiple"
			:clear-on-select="!multiple"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			@input="handleInput($event, multiple, multipleReturnType)"
		>
            <span slot="noOptions">
                {{ $t("no_options") }}
            </span>
			<span slot="noResult">
                {{ $t("no_result") }}
            </span>
			<template slot="selection" slot-scope="{ values, search, isOpen }" v-if="multiple">
				<span class="multiselect__single" v-if="values.length && !isOpen">
					{{ translateNSelected(values) }}
				</span>
			</template>
		</multiselect>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
	</div>
</template>

<script>
	// Helpers
	import translateNSelected from "@/helpers/translateNSelected";
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";
	
	// Services
	import DepartmentService from "@/services/DepartmentService";
	
	// Other
	import qs from "qs";
	
	export default {
		props: {
			faculty_code: {
				default: null,
			},
			value: {
				default: null,
			},
			validateError: {
				type: String,
				default: "",
			},
			multiple: {
				type: Boolean,
				default: true,
			},
			multipleReturnType: {
				type: String,
				default: 'withComma',
			},
		},
		data() {
			return {
				selected: this.value,
				options: [],
			};
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options, this.multiple, this.multipleReturnType)
			},
			faculty_code: {
				handler: function (val) {
					this.clearOptions();
					if (val) {
						this.getOptions();
					}
				},
			},
		},
		created() {
			this.clearOptions();
			this.getOptions()
		},
		methods: {
			translateNSelected: translateNSelected,
			handleInput: handleInput,
			setSelected: setSelected,
			getOptions() {
				let filter = {
					faculty_code: this.faculty_code,
				}
				const config = {
					params: {
						filter: filter,
						sort: this.getLocaleField("name"),
						limit: -1,
					},
					paramsSerializer: (params) =>
						qs.stringify(params, {encode: false}),
				}
				this.clearOptions();
				DepartmentService.getAll(config)
				                 .then((response) => {
					                 const data = response.data.data;
					                 data.map((item) => {
						                 this.options.push({
							                 value: item.code,
							                 text: item.code + ' - ' + this.getLocaleText(item, 'name'),
						                 });
					                 });
				                 })
				                 .then(() => {
					                 this.selected = this.setSelected(this.value, this.options, this.multiple, this.multipleReturnType)
					                 if (this.selected == null || this.selected.length == 0) {
						                 this.$emit("input", null)
					                 }
				                 })
			},
			clearOptions() {
				this.options = [];
			}
		}
	}
</script>
