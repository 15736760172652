<template>
	<div>
		<div class="border p-5 mb-5">
			<b-row>
				<b-col class="mb-0">
					<h6>Country</h6>
					<country-selectbox v-model="country" :public="true"/>
					<span>{{ country }}</span>
				</b-col>
			</b-row>
		</div>
		<div class="border p-5">
			<b-row>
				<b-col md="12" class="mb-4">
					<h6>Null</h6>
					<university-selectbox v-model="value1" :countryId="country" :public="true"/>
					<span>{{ value1 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Single Selected</h6>
					<university-selectbox v-model="value2" :countryId="country" :public="true"/>
					<span>{{ value2 }}</span>
				</b-col>
				<b-col md="12" class="mb-4">
					<h6>Validate Error</h6>
					<university-selectbox v-model="value3" :countryId="country" :public="true" :validate-error="value3ValidateError"/>
					<span>{{ value3 }}</span>
				</b-col>
				<b-col cols="12">
					<b-button variant="primary" @click="change()">
						{{ $t('change').toUpper() }}
					</b-button>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
	import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox";
	import UniversitySelectbox from "@/components/interactive-fields/UniversitySelectbox";

	export default {
		components: {
			CountrySelectbox,
			UniversitySelectbox,
		},
		data() {
			return {
				country: null,

				value1: null,
				value2: 1,
				value3: 1,
				value3ValidateError: 'Validate Error is Working',
			}
		},
		methods: {
			change() {
				this.value2 = 2
				this.value3 = 2
				this.value3ValidateError = 'Error'
			}
		}
	};
</script>

