<template>
	<div>
		<b-row>
			<b-col md="12" class="mb-4">
				<h6>Null</h6>
				<payment-products-selectbox v-model="value1"/>
				<span>{{ value1 }}</span>
			</b-col>
			<b-col md="12" class="mb-4">
				<h6>Single Selected</h6>
				<payment-products-selectbox v-model="value2"/>
				<span>{{ value2 }}</span>
			</b-col>
			<b-col md="12" class="mb-4">
				<h6>Multiple Selected</h6>
				<payment-products-selectbox v-model="value3" :multiple="true"/>
				<span>{{ value3 }}</span>
			</b-col>
			<b-col md="12" class="mb-4">
				<h6>Validate Error</h6>
				<payment-products-selectbox v-model="value4" :validate-error="value4ValidateError"/>
				<span>{{ value4 }}</span>
			</b-col>
			<b-col cols="12">
				<b-button variant="primary" @click="change()">
					{{ $t('change').toUpper() }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	import PaymentProductsSelectbox from "@/components/interactive-fields/PaymentProductsSelectbox";

	export default {
		components: {
			PaymentProductsSelectbox,
		},
		data() {
			return {
				value1: null,
				value2: 1,
				value3: [1, 2],
				value4: 1,
				value4ValidateError: 'Validate Error is Working',
			}
		},
		methods: {
			change() {
				this.value2 = 2
				this.value3 = [2]
				this.value4 = 2
				this.value4ValidateError = 'Error'
			}
		}
	};
</script>

