<template>
	<div>
		<b-row>
			<b-col md="12" class="mb-4">
				<h6>Null</h6>
				<course-auto-complete v-model="value1"/>
				<span>{{ value1 }}</span>
			</b-col>
			<b-col md="12" class="mb-4">
				<h6>Single Selected</h6>
				<course-auto-complete v-model="value2" :valueObject="value2Object"/>
				<span>{{ value2 }}</span>
			</b-col>
			<b-col md="12" class="mb-4">
				<h6>Validate Error</h6>
				<course-auto-complete v-model="value3" :valueObject="value3Object" :validate-error="value3ValidateError"/>
				<span>{{ value3 }}</span>
			</b-col>
			<b-col cols="12">
				<b-button variant="primary" @click="change()">
					{{ $t('change').toUpper() }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete";

	export default {
		components: {
			CourseAutoComplete,
		},
		data() {
			return {
				value1: null,
				value2: 47586,
				value2Object: {value: 47586, text: '1210223@KTUN - Matematik II'},
				value3: 47586,
				value3Object: {value: 47586, text: '1210223@KTUN - Matematik II'},
				value3ValidateError: 'Validate Error is Working',
			}
		},
		methods: {
			change() {
				this.value2 = 1984
				this.value2Object = {value: 1984, text: 'MAR1001 - Seminar I'}
				this.value3 = 1984
				this.value3Object = {value: 1984, text: 'MAR1001 - Seminar I'}
				this.value3ValidateError = 'Error'
			}
		}
	};
</script>

