<template>
	<div class="items">
		<div v-for="item in values">
			<div class="item" :class="selected==item.key ? 'active':''" @click="handleInput(item.key)">
				{{ item.text }}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			values: {
				type: Array,
				default: null
			},
			value: {
				default: null
			},
		},
		data() {
			return {
				selected: null,
			}
		},
		watch: {
			value: function (newValue) {
				this.selected = newValue;
			}
		},
		created() {
			this.selected = this.value;
		},
		methods: {
			handleInput(event) {
				this.selected = event
				this.$emit('input', event)
			}
		}
	}
</script>

<style scoped>
	.items {
		display: flex;
	}
	
	.item {
		background: #f1f1f1;
		padding: 7px 15px;
		margin-right: 5px;
		cursor: pointer;
		border-radius: .25rem;
	}
	
	.item.active {
		background: #00aeef;
		color: #ffffff;
	}
</style>
