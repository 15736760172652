<template>
    <div class="p-5">
        <b-row>
            <b-col cols="12" md="4" lg="3">
                <h4>Components</h4>
                <b-list-group class="mb-5">
                    <b-list-group-item v-for="(component,componentKey) in components"
                                       :key="component.key"
                                       :variant="component.key==selectedComponent ? 'primary':''"
                                       @click="selectedComponent = component.key"
                                       class="d-flex justify-content-between align-items-center cursor-pointer"
                                       style="padding:2px 10px"
                    >

                        <small class="font-weight-bold">{{ componentKey + 1 }}. {{ component.key }}</small>
                        <div>
                            <!-- Error -->
                            <b-badge variant="danger" class="p-1 mr-1 rounded-0" pill v-if="component.isError">Error</b-badge>

                            <!-- Public or Auth-->
                            <b-badge variant="success" class="p-1 mr-1 rounded-0" pill v-if="component.isPublic==true">Public</b-badge>
                            <b-badge variant="danger" class="p-1 mr-1 rounded-0" pill v-if="component.isPublic==false">No Public</b-badge>
                        </div>
                    </b-list-group-item>
                </b-list-group>
            </b-col>
            <b-col cols="12" md="8" lg="9">
                <div>
                    <h4>{{ componentName }}<small> &nbsp; {{ selectedComponent }}</small></h4>
                    <div class="border p-5 rounded-sm mb-5">
                        <academic-years-selectbox v-if="selectedComponent=='AcademicYearsSelectbox'"/>
                        <active-module-selectbox v-else-if="selectedComponent=='ActiveModuleSelectbox'"/>
                        <advisors-selectbox v-else-if="selectedComponent=='AdvisorsSelectbox'"/>
                        <approved-declined-selectbox v-else-if="selectedComponent=='ApprovedDeclinedSelectbox'"/>
                        <bank-selectbox v-else-if="selectedComponent=='BankSelectbox'"/>
                        <building-selectbox v-else-if="selectedComponent=='BuildingSelectbox'"/>
                        <campus-selectbox v-else-if="selectedComponent=='CampusSelectbox'"/>
                        <city-selectbox v-else-if="selectedComponent=='CitySelectbox'"/>
                        <classroom-selectbox v-else-if="selectedComponent=='ClassroomSelectbox'"/>
                        <country-and-university-selectbox v-else-if="selectedComponent=='CountryAndUniversitySelectbox'"/>
                        <country-selectbox v-else-if="selectedComponent=='CountrySelectbox'"/>
                        <course-auto-complete v-else-if="selectedComponent=='CourseAutoComplete'"/>
                        <credit-between v-else-if="selectedComponent=='CreditBetween'"/>
                        <currency-selectbox v-else-if="selectedComponent=='CurrencySelectbox'"/>
                        <curriculum-selectbox v-else-if="selectedComponent=='CurriculumSelectbox'"/>
                        <days-selectbox v-else-if="selectedComponent=='DaysSelectbox'"/>
                        <department-selectbox v-else-if="selectedComponent=='DepartmentSelectbox'"/>
                        <department-selectbox-multiple v-else-if="selectedComponent=='DepartmentSelectboxMultiple'"/>
                        <district-outline-selectbox v-else-if="selectedComponent=='DistrictOutlineSelectbox'"/>
                        <document-type-selectbox v-else-if="selectedComponent=='DocumentTypeSelectbox'"/>
                        <elective-pool-selectbox v-else-if="selectedComponent=='ElectivePoolSelectbox'"/>
                        <faculty-selectbox v-else-if="selectedComponent=='FacultySelectbox'"/>
                        <faculty-selectbox-multiple v-else-if="selectedComponent=='FacultySelectboxMultiple'"/>
                        <gender-selectbox v-else-if="selectedComponent=='GenderSelectbox'"/>
                        <grade-conversions-selectbox v-else-if="selectedComponent=='GradeConversionsSelectbox'"/>
                        <grade-selectbox v-else-if="selectedComponent=='GradeSelectbox'"/>
                        <grade-system-selectbox v-else-if="selectedComponent=='GradeSystemSelectbox'"/>
                        <medicine-groups-selectbox v-else-if="selectedComponent=='MedicineGroupsSelectbox'"/>
                        <module-selectbox v-else-if="selectedComponent=='ModuleSelectbox'"/>
                        <multi-parameter-selectbox v-else-if="selectedComponent=='MultiParameterSelectbox'"/>
                        <multi-selectbox v-else-if="selectedComponent=='MultiSelectbox'"/>
                        <multi-text v-else-if="selectedComponent=='MultiText'"/>
                        <osym-quota-selectbox v-else-if="selectedComponent=='OsymQuotaSelectbox'"/>
                        <parameter-id-selectbox v-else-if="selectedComponent=='ParameterIdSelectbox'"/>
                        <parameter-selectbox v-else-if="selectedComponent=='ParameterSelectbox'"/>
                        <payment-periods-selectbox v-else-if="selectedComponent=='PaymentPeriodsSelectbox'"/>
                        <payment-plan-types-selectbox v-else-if="selectedComponent=='PaymentPlanTypesSelectbox'"/>
                        <payment-products-selectbox v-else-if="selectedComponent=='PaymentProductsSelectbox'"/>
                        <payment-program-level-selectbox v-else-if="selectedComponent=='PaymentProgramLevelSelectbox'"/>
                        <payment-types-selectbox v-else-if="selectedComponent=='PaymentTypesSelectbox'"/>
                        <payment-type-type-selectbox v-else-if="selectedComponent=='PaymentTypeTypeSelectbox'"/>
                        <period-selectbox v-else-if="selectedComponent=='PeriodSelectbox'"/>
                        <permission-selectbox v-else-if="selectedComponent=='PermissionSelectbox'"/>
                        <prep-classes-selectbox v-else-if="selectedComponent=='PrepClassesSelectbox'"/>
                        <prep-instructors-selectbox v-else-if="selectedComponent=='PrepInstructorsSelectbox'"/>
                        <program-selectbox v-else-if="selectedComponent=='ProgramSelectbox'"/>
                        <program-selectbox-multiple v-else-if="selectedComponent=='ProgramSelectboxMultiple'"/>
                        <radio-group-selectbox v-else-if="selectedComponent=='RadioGroupSelectbox'"/>
                        <role-selectbox v-else-if="selectedComponent=='RoleSelectbox'"/>
                        <scholarship-rate-selectbox v-else-if="selectedComponent=='ScholarshipRateSelectbox'"/>
                        <scholarship-types-selectbox v-else-if="selectedComponent=='ScholarshipTypesSelectbox'"/>
                        <school-selectbox v-else-if="selectedComponent=='SchoolSelectbox'"/>
                        <section-type v-else-if="selectedComponent=='SectionType'"/>
                        <select-date v-else-if="selectedComponent=='SelectDate'"/>
                        <school v-else-if="selectedComponent=='SchoolSelectbox'"/>
                        <semester-numbered-selectbox v-else-if="selectedComponent=='SemesterNumberedSelectbox'"/>
                        <semesters-selectbox v-else-if="selectedComponent=='SemestersSelectbox'"/>
                        <semester-type-selectbox v-else-if="selectedComponent=='SemesterTypeSelectbox'"/>
                        <slot-type-selectbox v-else-if="selectedComponent=='SlotTypeSelectbox'"/>
                        <staff-auto-complete v-else-if="selectedComponent=='StaffAutoComplete'"/>
                        <staff-auto-suggest v-else-if="selectedComponent=='StaffAutoSuggest'"/>
                        <status-selectbox v-else-if="selectedComponent=='StatusSelectbox'"/>
                        <students-auto-complete v-else-if="selectedComponent=='StudentsAutoComplete'"/>
                        <thesis-selectbox v-else-if="selectedComponent=='ThesisSelectbox'"/>
                        <true-false-selectbox v-else-if="selectedComponent=='TrueFalseSelectbox'"/>
                        <unit-selectbox v-else-if="selectedComponent=='UnitSelectbox'"/>
                        <units-selectbox v-else-if="selectedComponent=='UnitsSelectbox'"/>
                        <university-selectbox v-else-if="selectedComponent=='UniversitySelectbox'"/>
                        <yes-no-selectbox v-else-if="selectedComponent=='YesNoSelectbox'"/>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import AppLayout from '@/layouts/AppLayout';
    import Header from '@/layouts/AppLayout/Header'
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

    import AcademicYearsSelectbox from './page/AcademicYearsSelectbox';
    import ActiveModuleSelectbox from './page/ActiveModuleSelectbox';
    import AdvisorsSelectbox from './page/AdvisorsSelectbox';
    import ApprovedDeclinedSelectbox from './page/ApprovedDeclinedSelectbox';
    import BankSelectbox from './page/BankSelectbox';
    import BuildingSelectbox from './page/BuildingSelectbox';
    import CampusSelectbox from './page/CampusSelectbox';
    import CitySelectbox from './page/CitySelectbox';
    import ClassroomSelectbox from './page/ClassroomSelectbox';
    import CountryAndUniversitySelectbox from './page/CountryAndUniversitySelectbox';
    import CountrySelectbox from './page/CountrySelectbox';
    import CourseAutoComplete from './page/CourseAutoComplete';
    import CreditBetween from './page/CreditBetween';
    import CurrencySelectbox from './page/CurrencySelectbox';
    import CurriculumSelectbox from './page/CurriculumSelectbox';
    import DaysSelectbox from './page/DaysSelectbox';
    import DepartmentSelectbox from './page/DepartmentSelectbox';
    import DepartmentSelectboxMultiple from './page/DepartmentSelectboxMultiple';
    import DistrictOutlineSelectbox from './page/DistrictOutlineSelectbox';
    import DocumentTypeSelectbox from './page/DocumentTypeSelectbox';
    import ElectivePoolSelectbox from './page/ElectivePoolSelectbox';
    import FacultySelectbox from './page/FacultySelectbox';
    import FacultySelectboxMultiple from './page/FacultySelectboxMultiple';
    import GenderSelectbox from './page/GenderSelectbox';
    import GradeConversionsSelectbox from './page/GradeConversionsSelectbox';
    import GradeSelectbox from './page/GradeSelectbox';
    import GradeSystemSelectbox from './page/GradeSystemSelectbox';
    import LanguageSelectbox from './page/LanguageSelectbox';
    import MedicineGroupsSelectbox from './page/MedicineGroupsSelectbox';
    import ModuleSelectbox from './page/ModuleSelectbox';
    import MultiParameterSelectbox from './page/MultiParameterSelectbox';
    import MultiSelectbox from './page/MultiSelectbox';
    import MultiText from './page/MultiText';
    import OsymQuotaSelectbox from './page/OsymQuotaSelectbox';
    import ParameterIdSelectbox from './page/ParameterIdSelectbox';
    import ParameterSelectbox from './page/ParameterSelectbox';
    import PaymentPeriodsSelectbox from './page/PaymentPeriodsSelectbox';
    import PaymentPlanTypesSelectbox from './page/PaymentPlanTypesSelectbox';
    import PaymentProductsSelectbox from './page/PaymentProductsSelectbox';
    import PaymentProgramLevelSelectbox from './page/PaymentProgramLevelSelectbox';
    import PaymentTypesSelectbox from './page/PaymentTypesSelectbox';
    import PaymentTypeTypeSelectbox from './page/PaymentTypeTypeSelectbox';
    import PeriodSelectbox from './page/PeriodSelectbox';
    import PermissionSelectbox from './page/PermissionSelectbox';
    import PrepClassesSelectbox from './page/PrepClassesSelectbox';
    import PrepInstructorsSelectbox from './page/PrepInstructorsSelectbox';
    import ProgramSelectbox from './page/ProgramSelectbox';
    import ProgramSelectboxMultiple from './page/ProgramSelectboxMultiple';
    import RadioGroupSelectbox from './page/RadioGroupSelectbox';
    import RoleSelectbox from './page/RoleSelectbox';
    import ScholarshipRateSelectbox from './page/ScholarshipRateSelectbox';
    import ScholarshipTypesSelectbox from './page/ScholarshipTypesSelectbox';
    import SchoolSelectbox from './page/SchoolSelectbox';
    import SectionType from './page/SectionType';
    import SelectDate from './page/SelectDate';
    import SemesterNumberedSelectbox from './page/SemesterNumberedSelectbox';
    import SemestersSelectbox from './page/SemestersSelectbox';
    import SemesterTypeSelectbox from './page/SemesterTypeSelectbox';
    import SlotTypeSelectbox from './page/SlotTypeSelectbox';
    import StaffAutoComplete from './page/StaffAutoComplete';
    import StaffAutoSuggest from './page/StaffAutoSuggest';
    import StatusSelectbox from './page/StatusSelectbox';
    import StudentsAutoComplete from './page/StudentsAutoComplete';
    import ThesisSelectbox from './page/ThesisSelectbox';
    import TrueFalseSelectbox from './page/TrueFalseSelectbox';
    import UnitSelectbox from './page/UnitSelectbox';
    import UnitsSelectbox from './page/UnitsSelectbox';
    import UniversitySelectbox from './page/UniversitySelectbox';
    import YesNoSelectbox from './page/YesNoSelectbox';

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,

            AcademicYearsSelectbox,
            ActiveModuleSelectbox,
            AdvisorsSelectbox,
            ApprovedDeclinedSelectbox,
            BankSelectbox,
            BuildingSelectbox,
            CampusSelectbox,
            CitySelectbox,
            ClassroomSelectbox,
            CountryAndUniversitySelectbox,
            CountrySelectbox,
            CourseAutoComplete,
            CreditBetween,
            CurrencySelectbox,
            CurriculumSelectbox,
            DaysSelectbox,
            DepartmentSelectbox,
            DepartmentSelectboxMultiple,
            DistrictOutlineSelectbox,
            DocumentTypeSelectbox,
            ElectivePoolSelectbox,
            FacultySelectbox,
            FacultySelectboxMultiple,
            GenderSelectbox,
            GradeConversionsSelectbox,
            GradeSelectbox,
            GradeSystemSelectbox,
            LanguageSelectbox,
            MedicineGroupsSelectbox,
            ModuleSelectbox,
            MultiParameterSelectbox,
            MultiSelectbox,
            MultiText,
            OsymQuotaSelectbox,
            ParameterIdSelectbox,
            ParameterSelectbox,
            PaymentPeriodsSelectbox,
            PaymentPlanTypesSelectbox,
            PaymentProductsSelectbox,
            PaymentProgramLevelSelectbox,
            PaymentTypesSelectbox,
            PaymentTypeTypeSelectbox,
            PeriodSelectbox,
            PermissionSelectbox,
            PrepClassesSelectbox,
            PrepInstructorsSelectbox,
            ProgramSelectbox,
            ProgramSelectboxMultiple,
            RadioGroupSelectbox,
            RoleSelectbox,
            ScholarshipRateSelectbox,
            ScholarshipTypesSelectbox,
            SchoolSelectbox,
            SectionType,
            SelectDate,
            SemesterNumberedSelectbox,
            SemestersSelectbox,
            SemesterTypeSelectbox,
            SlotTypeSelectbox,
            StaffAutoComplete,
            StaffAutoSuggest,
            StatusSelectbox,
            StudentsAutoComplete,
            ThesisSelectbox,
            TrueFalseSelectbox,
            UnitSelectbox,
            UnitsSelectbox,
            UniversitySelectbox,
            YesNoSelectbox
        },
        metaInfo() {
            return {
                title: 'Manuals'
            }
        },
        computed: {
            componentName() {
                let data = this.components.find(item => item.key == this.selectedComponent);
                return data && data.title ? data.title : '';
            }
        },
        data() {
            return {
                selectedComponent: '',
                components: [
                    {
                        key: 'AcademicYearsSelectbox',
                        title: 'Akademik Yıl',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'ActiveModuleSelectbox',
                        title: 'Aktive Modülü',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'AdvisorsSelectbox',
                        title: 'Danışman Seçimi',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'ApprovedDeclinedSelectbox',
                        title: 'Onaylandı - Reddedildi Selectbox',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'BankSelectbox',
                        title: 'Banka',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'BuildingSelectbox',
                        title: 'Binalar',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'CampusSelectbox',
                        title: 'Kampüsler',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'CitySelectbox',
                        title: 'Şehir',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'ClassroomSelectbox',
                        title: 'Sınıflar',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'CountryAndUniversitySelectbox',
                        title: 'Ülkelere göre Üniversiteler',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'CountrySelectbox',
                        title: 'Ülkeler',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'CourseAutoComplete',
                        title: 'Dersler - Otomatik Tamamlama',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'CreditBetween',
                        title: 'Kredi ',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'CurrencySelectbox',
                        title: 'Para Birimi',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'CurriculumSelectbox',
                        title: 'Müfredatlar',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'DaysSelectbox',
                        title: 'Günler',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'DepartmentSelectbox',
                        title: 'Bölümler',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'DepartmentSelectboxMultiple',
                        title: 'Multiple Bölümler',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'DistrictOutlineSelectbox',
                        title: 'İlçeler',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'DocumentTypeSelectbox',
                        title: 'Document Tipleri',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'ElectivePoolSelectbox',
                        title: 'Seçmeli Ders Havuzu',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'FacultySelectbox',
                        title: 'Fakülte',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'FacultySelectboxMultiple',
                        title: 'Fakülte - Multiple',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'GenderSelectbox',
                        title: 'Cinsiyet',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'GradeConversionsSelectbox',
                        title: 'Not Dönüşünleri ',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'GradeSelectbox',
                        title: 'Not Durumları',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'GradeSystemSelectbox',
                        title: 'Not Dönüşünleri',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'LanguageSelectbox',
                        title: 'Dil Seçimi',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'MedicineGroupsSelectbox',
                        title: 'İlaç Grupları',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'ModuleSelectbox',
                        title: 'Module',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'MultiParameterSelectbox',
                        title: 'Multi Parameter',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'MultiSelectbox',
                        title: 'Multi Selectbox',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'MultiText',
                        title: 'Multi Text',
                        isError: false,
                        isPublic: false
                    },

                    /*
                    {
                        key: 'NoteAssessment',
                        title: 'Note Assessment',
                        isError: true
                    },
                    {
                        key: 'NoteAssessmentDetail',
                        title: 'Note Assessment Detail',
                        isError: true
                    },
                     */

                    {
                        key: 'OsymQuotaSelectbox',
                        title: 'Osym Kota',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'ParameterIdSelectbox',
                        title: 'Parametre Selectbox - Return Id',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'ParameterSelectbox',
                        title: 'Parametre Selectbox',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'PaymentPeriodsSelectbox',
                        title: 'Ödeme Dönemleri',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'PaymentPlanTypesSelectbox',
                        title: 'Ödeme Planları',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'PaymentProductsSelectbox',
                        title: 'Ödeme Ürünleri',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'PaymentProgramLevelSelectbox',
                        title: 'Payment Program Level Selectbox',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'PaymentTypesSelectbox',
                        title: 'Ödeme Türleri',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'PaymentTypeTypeSelectbox',
                        title: 'Payment Type Type Selectbox',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'PeriodSelectbox',
                        title: 'Dönem',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'PermissionSelectbox',
                        title: 'Yetkiler',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'PrepClassesSelectbox',
                        title: 'Hazırlık Sınıfları',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'PrepInstructorsSelectbox',
                        title: 'Hazırlık Sınıfları',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'ProgramSelectbox',
                        title: 'ProgramSelectbox',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'ProgramSelectboxMultiple',
                        title: 'ProgramSelectboxMultiple',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'RadioGroupSelectbox',
                        title: 'Radio Group Selectbox',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'RoleSelectbox',
                        title: 'Role Selectbox',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'ScholarshipRateSelectbox',
                        title: 'Scholarship Rate Selectbox',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'ScholarshipTypesSelectbox',
                        title: 'Scholarship Types Selectbox',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'SchoolSelectbox',
                        title: 'School Selectbox',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'SectionType',
                        title: 'Section Type',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'SemesterNumberedSelectbox',
                        title: 'Semester Numbered Selectbox',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'SemestersSelectbox',
                        title: 'Semester Selectbox',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'SemesterTypeSelectbox',
                        title: 'Semester Type Selectbox',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'SelectDate',
                        title: 'Select Date',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'SlotTypeSelectbox',
                        title: 'SlotTipi',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'StaffAutoComplete',
                        title: 'Semester Auto Complate',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'StaffAutoSuggest',
                        title: 'Personel - Arama',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'StatusSelectbox',
                        title: 'Durum',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'StudentsAutoComplete',
                        title: 'Students Auto Complete',
                        isError: true,
                        isPublic: false
                    },
                    {
                        key: 'ThesisSelectbox',
                        title: 'Tezli/Tezsiz',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'TrueFalseSelectbox',
                        title: 'True/False',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'UnitSelectbox',
                        title: 'Unit Selectbox',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'UnitsSelectbox',
                        title: 'Units Selectbox',
                        isError: false,
                        isPublic: false
                    },
                    {
                        key: 'UniversitySelectbox',
                        title: 'University Selectbox',
                        isError: false,
                        isPublic: true
                    },
                    {
                        key: 'YesNoSelectbox',
                        title: 'Evet/Hayır',
                        isError: false,
                        isPublic: true
                    }
                ]
            }
        }
    }
</script>

