<template>
	<div>
		<multiselect
			v-model="selected"
			:class="validateError != '' ? 'box-border-color' : ''"
			:clear-on-select="!multiple"
			:close-on-select="!multiple"
			:deselect-label="''"
			:multiple="multiple"
			:options="options"
			:placeholder="$t('select')"
			:select-label="''"
			:selected-label="''"
			label="text"
			track-by="value"
			@input="handleInput($event, multiple, multipleReturnType)"
		>
			<template v-if="multiple"
			          slot="selection"
			          slot-scope="{ values, search, isOpen}"
			>
                <span v-if="values.length && !isOpen" class="multiselect__single">
	                {{ translateNSelected(values,10) }}
                </span>
			</template>
			<span slot="noOptions">
				{{ $t("no_options") }}
            </span>
			<span slot="noResult">
                {{ $t("no_result") }}
            </span>
		</multiselect>
	</div>
</template>

<script>
	// Helpers
	import translateNSelected from "@/helpers/translateNSelected";
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";

	// Services
	import GradeService from "@/services/GradeService";

    // Other
    import qs from "qs";

	export default {
		props: {
			value: {
				default: null,
			},
			validateError: {
				type: String,
				default: "",
			},
			multiple: {
				type: Boolean,
				default: false,
			},
			multipleReturnType: {
				default: 'withComma'
			},
            grading_system_id: {
                default: null,
            },
            gradingSystemIdRequired: {
                type: Boolean,
                default:true
            },
            valueType:{
			    default: 'id'
            },
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options, this.multiple, this.multipleReturnType)
			},
            grading_system_id: {
                handler: function (newValue, oldValue) {
                    if (newValue != oldValue) {
                        this.getOptions()
                    }
                }
            },
		},
		data() {
			return {
				selected: null,
				options: [],
			};
		},
		created() {
			this.getOptions();
		},
		methods: {
			translateNSelected: translateNSelected,
			handleInput: handleInput,
			setSelected: setSelected,
			getOptions() {
			    if(this.grading_system_id == null && this.gradingSystemIdRequired){
			        return;
                }

				const config = {
					params: {
						limit: -1,
                        filter: {
                            grading_system_id: this.grading_system_id
                        },
					},
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
				};
				this.options = [];
				GradeService.getOptions(config).then((response) => {
					            const data = response.data.data;
                                let allData = []
                                data.forEach(itm => {
                                    allData.push(itm.grade)
                                })
                                let unique = [...new Set(allData)];

					            let added=[];
					            data.map((item) => {
					                if(added.includes(item[this.valueType])){
					                    return;
                                    }
					                added.push(item[this.valueType]);
						            this.options.push({
							            value: item[this.valueType],
							            text: item.grade,
						            });
					            });

                                if(this.valueType == 'grade'){
                                    let newArray = []
                                    unique.forEach((itm, index) => {
                                        newArray.push({
                                            value: itm,
                                            text: itm
                                        })
                                    })
                                    this.options = newArray
                                }
				            }).then(() => {
					            this.selected = this.setSelected(this.value, this.options, this.multiple, this.multipleReturnType)
				            })
			}
		}
	}
</script>
